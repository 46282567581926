import {
    IMessage,
    InfiniteList,
    ISpeedDialActionProps,
    MessageType,
    SpeedDial,
    useConfirmContext,
} from '@localina/core';
import { EmailIcon, TextAfterIcon, TextBeforeIcon } from '@localina/icons';
import { useQueryClient } from '@tanstack/react-query';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate } from 'react-router-dom';
import { useHaveAccountFeatures } from '../../api/queries/account';
import { useRestaurantMessages } from '../../api/queries/messages';
import { queryKeys } from '../../api/queries/query-keys';
import { MessageListItem, Page } from '../../components';
import { Path } from '../../enums';
import { PathUtils } from '../../utils';

interface IPathParams {
    restaurantId: string;
}

const Messages: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useMatch(Path.RESTAURANT_SETTINGS_MESSAGES)?.params as IPathParams;
    const { restaurantId } = params;

    const { alert } = useConfirmContext();

    const restaurantMessagesQuery = useRestaurantMessages();
    const queryClient = useQueryClient();
    const handleMessage = (item: IMessage) => {
        queryClient.setQueryData(queryKeys.restaurants.single.messages.single(restaurantId, item.id), item);
        navigate(
            PathUtils.generateUrl(Path.RESTAURANT_SETTINGS_MESSAGE, {
                restaurantId,
                messageId: item.id,
            }),
        );
    };

    const [canUseConfirmationMail] = useHaveAccountFeatures(['confirmationMail']);

    const handleCreateMessage = (type: MessageType) => () => {
        if (!canUseConfirmationMail && type === MessageType.CONFIRMATION_MAIL) {
            alert({
                title: t('common.features.missingFeatureTitle'),
                msg: t('common.features.paidPlanOnly'),
            });
        } else {
            navigate(
                PathUtils.generateUrl(Path.RESTAURANT_SETTINGS_MESSAGE, {
                    restaurantId,
                    messageId: type,
                }),
            );
        }
    };

    const createActions: ISpeedDialActionProps[] = [
        {
            label: t('messages.actions.createGoodbyeMessage'),
            icon: <TextAfterIcon />,
            onClick: handleCreateMessage(MessageType.GOODBYE),
        },
        {
            label: t('messages.actions.createWelcomeMessage'),
            icon: <TextBeforeIcon />,
            onClick: handleCreateMessage(MessageType.WELCOME),
        },
        {
            label: t('messages.actions.createConfirmationEmailMessage'),
            icon: <EmailIcon />,
            onClick: handleCreateMessage(MessageType.CONFIRMATION_MAIL),
        },
    ];

    return (
        <Page name="messages" title={t('messages.title')} isLoading={restaurantMessagesQuery.isLoading}>
            <div id="messages">
                <InfiniteList
                    scrollableTarget="messages"
                    hasMore={Boolean(restaurantMessagesQuery.hasNextPage)}
                    items={(restaurantMessagesQuery.data?.pages || []).flatMap((page) =>
                        page.items.map((item) => (
                            <MessageListItem key={item.id} message={item} onClick={handleMessage} />
                        )),
                    )}
                    emptyMessage={restaurantMessagesQuery.isLoading ? undefined : t('messages.list.empty')}
                    loadNext={restaurantMessagesQuery.fetchNextPage}
                />
            </div>
            <SpeedDial label="create" actions={createActions} fixed />
        </Page>
    );
};

export default Messages;
