import { Slider, Switch } from '@localina/core';
import {
    Accordion as MuiAccordion,
    AccordionDetails as MuiAccordionDetails,
    AccordionSummary as MuiAccordionSummary,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

interface IRequestModeSwitchWithSliderProps {
    activationPercentage: number | null;
    setActivationPercentage: (newValue: number | null) => void;
}

const RequestModeSwitchWithSlider = (props: IRequestModeSwitchWithSliderProps) => {
    const { t } = useTranslation();

    const [expanded, setExpanded] = useState(props.activationPercentage !== null);

    const onSwitchToggle = (value: boolean) => {
        setExpanded(value);
        if (!value) {
            props.setActivationPercentage(null);
        }
    };

    useEffect(() => {
        setExpanded(props.activationPercentage !== null);
    }, [props.activationPercentage]);

    useEffect(() => {
        if (expanded && props.activationPercentage == null) {
            props.setActivationPercentage(0);
        }
    }, [expanded]);

    return (
        <div className="request-mode-switch-accordion">
            <MuiAccordion expanded={expanded}>
                <MuiAccordionSummary>
                    <Switch
                        label={t('availability.shiftView.fields.requestMode')}
                        tooltip={t('availability.shiftView.fields.requestModeInfo')}
                        value={expanded}
                        onChange={onSwitchToggle}
                    />
                </MuiAccordionSummary>
                <MuiAccordionDetails className="accordion-details-style">
                    <Slider
                        name="requestModeCapacityPercentage"
                        value={props.activationPercentage || 0}
                        onChange={props.setActivationPercentage}
                        step={1}
                        valueLabelDisplay={'on'}
                        triggerChangeEventForLastValueOnly
                        marks={[
                            { value: 2, label: '0%' },
                            { value: 25, label: '25%' },
                            { value: 50, label: '50%' },
                            { value: 75, label: '75%' },
                            { value: 96, label: '100%' },
                        ]}
                    />
                </MuiAccordionDetails>
            </MuiAccordion>
        </div>
    );
};

export default RequestModeSwitchWithSlider;
