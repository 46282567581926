import { EMAIL_REGEX, GuestStatus, ISalutationType } from '@localina/core';
import { isPossiblePhoneNumber, isValidPhoneNumber } from 'libphonenumber-js';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import * as yup from 'yup';

export function useGuestSchema() {
    const { t } = useTranslation('core');

    return useMemo(
        () => ({
            allergies: yup.string().max(200, t('validation.string_too_long', { max: 200 })),
            company: yup.string().max(30, t('validation.string_too_long', { max: 30 })),
            emailRequired: yup.boolean(),
            email: yup
                .string()
                .matches(EMAIL_REGEX, {
                    message: t('errors.invalidEmail'),
                    excludeEmptyString: true,
                })
                .max(50, t('validation.string_too_long', { max: 50 }))
                .when('emailRequired', {
                    is: true,
                    then: (schema) => schema.required().min(6, t('validation.string_too_short', { min: 6 })),
                    otherwise: (schema) =>
                        schema
                            .notRequired()
                            .ensure()
                            .matches(/.{6,}/, {
                                excludeEmptyString: true,
                                message: t('validation.string_too_short', { min: 6 }),
                            }),
                })
                .when('emailRequired', {
                    is: true,
                    then: (schema) => schema.required(t('validation.required_field')),
                }),
            firstName: yup.string().max(40, t('validation.string_too_long', { max: 40 })),
            guestStatus: yup.string<GuestStatus>().nullable().defined(),
            lastName: yup
                .string()
                .required()
                .min(2, t('validation.string_too_short', { min: 2 }))
                .max(40, t('validation.string_too_long', { max: 40 })),
            salutation: yup.string<ISalutationType>().nullable(),
            phoneNumbers: yup
                .array()
                .of(
                    yup.object({
                        phone: yup
                            .string()
                            .required()
                            .test({
                                skipAbsent: true,
                                name: 'is-phone-number',
                                test(value, ctx) {
                                    if (!value) {
                                        return true;
                                    }
                                    if (
                                        !value.startsWith('+') ||
                                        !isPossiblePhoneNumber(value) ||
                                        !isValidPhoneNumber(value)
                                    ) {
                                        return ctx.createError({ message: t('validation.field_not_phone') });
                                    }
                                    return true;
                                },
                            })
                            .min(10, t('validation.string_too_short', { min: 10 }))
                            .max(15, t('validation.string_too_long', { max: 15 }))
                            .transform((curr, orig) => (orig === '' ? null : curr)),
                    }),
                )
                .required(),
            restaurantComment: yup.string().max(200, t('validation.string_too_long', { max: 200 })),
        }),
        [],
    );
}
