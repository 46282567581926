import { yupResolver } from '@hookform/resolvers/yup';
import {
    createEmptyMessageDisplayText,
    DATE_FORMAT,
    IMessageDisplayText,
    MessageLanguage,
    MessageType,
    SERVER_DATE_FORMAT,
    useConfirmContext,
} from '@localina/core';
import { EmailIcon, TextAfterIcon, TextBeforeIcon } from '@localina/icons';
import { isEqual, pick } from 'lodash';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { useMatch, useNavigate } from 'react-router-dom';
import { object } from 'yup';
import { useHaveAccountFeatures } from '../../api/queries/account';
import { useCreateMessage, useDeleteMessage, useGetMessage, useUpdateMessage } from '../../api/queries/messages';
import { Page } from '../../components';
import { Path } from '../../enums';
import { PathUtils } from '../../utils';
import { MessageDisplayTextFragment, MessageFragment } from './Fragments';
import { useDisplayTextSchema, useMessageSchema } from './message-utils';

interface IPathParams {
    restaurantId: string;
    messageId: MessageType | string;
}

interface IMessageDisplayTextState extends IMessageDisplayText {
    index: number;
}

const Message: React.FC = () => {
    const { t } = useTranslation();
    const navigate = useNavigate();
    const params = useMatch(Path.RESTAURANT_SETTINGS_MESSAGE)?.params as IPathParams;
    const { restaurantId, messageId } = params;

    const isEditing = !Object.values(MessageType).includes(messageId as MessageType);

    const [canUseConfirmationMail] = useHaveAccountFeatures(['confirmationMail']);
    const messageQuery = useGetMessage(messageId, {
        enabled: isEditing,
    });
    const createMessageMutation = useCreateMessage();
    const updateMessageMutation = useUpdateMessage();
    const deleteMessageMutation = useDeleteMessage();

    const { snackbar, confirm } = useConfirmContext();

    const messageType = messageQuery.data?.type || (messageId as MessageType);

    const messageSchema = useMessageSchema(messageQuery.data?.type || messageId);
    const displayTextSchema = useDisplayTextSchema(messageQuery.data?.type || messageId);

    const [isDefaultMessage, setIsDefaultMessage] = useState(!messageQuery.data?.from && !messageQuery.data?.to);

    const methods = useForm({
        mode: 'all',
        resolver: yupResolver(object().shape(messageSchema)),
        defaultValues: {
            name: messageQuery.data?.name || '',
            link: messageQuery.data?.link || '',
            from: messageQuery.data?.from || '',
            to: messageQuery.data?.to || '',
            displayTexts: messageQuery.data?.displayTexts || [],
        },
    });

    const { formState } = methods;
    const { isValid, isDirty, errors } = formState;

    const [displayTexts, link] = methods.watch(['displayTexts', 'link']);

    const displayTextsFieldArray = useFieldArray({
        control: methods.control,
        name: `displayTexts`,
    });

    const [selectedLanguageDisplayText, setSelectedLanguageDisplayText] = React.useState<
        IMessageDisplayTextState | undefined
    >(undefined);

    const handleBack = () => {
        if (selectedLanguageDisplayText) {
            setSelectedLanguageDisplayText(undefined);
        } else {
            navigate(PathUtils.generateUrl(Path.RESTAURANT_SETTINGS_MESSAGES, { restaurantId }));
        }
    };

    const handleCancel = () => {
        if (selectedLanguageDisplayText) {
            const { index, ...defaultValue } = selectedLanguageDisplayText;
            const isDefaultValueValid = object(displayTextSchema).isValidSync(defaultValue);

            if (isDefaultValueValid) {
                methods.resetField(`displayTexts.${index}`, { defaultValue, keepError: false, keepDirty: true });
                displayTextsFieldArray.update(index, defaultValue);
            } else {
                displayTextsFieldArray.remove(index);
            }
        }
        handleBack();
    };

    const onSaveSuccess = () => {
        snackbar({ msg: t('message.saved'), severity: 'success' });
        handleBack();
    };
    const onSaveError = () => {
        snackbar({ msg: t(`message.errors.save`), severity: 'error' });
    };
    const onDeleteError = () => {
        snackbar({ msg: t(`message.errors.delete`), severity: 'error' });
    };
    useEffect(() => {
        if (!isEditing) {
            methods.reset({
                name: '',
                link: '',
                from: '',
                to: '',
                displayTexts: [],
            });
            setIsDefaultMessage(true);
        } else if (messageQuery.isSuccess && messageQuery.data) {
            const dateTimeFrom = messageQuery.data.from
                ? DateTime.fromFormat(messageQuery.data.from, SERVER_DATE_FORMAT)
                : null;
            const dateTimeTo = messageQuery.data.to
                ? DateTime.fromFormat(messageQuery.data.to, SERVER_DATE_FORMAT)
                : null;

            methods.reset({
                name: messageQuery.data.name,
                link: messageQuery.data.link,
                from: dateTimeFrom?.toFormat(DATE_FORMAT) ?? '',
                to: dateTimeTo?.toFormat(DATE_FORMAT) ?? '',
                displayTexts: messageQuery.data.displayTexts,
            });
            setIsDefaultMessage(!dateTimeFrom?.isValid && !dateTimeTo?.isValid);
        }
    }, [messageQuery.data, isEditing]);

    const handleSave = () => {
        if (selectedLanguageDisplayText) {
            handleBack();
        } else if (isValid) {
            const formValues = methods.getValues();
            const dateTimeFrom = formValues.from ? DateTime.fromFormat(formValues.from, DATE_FORMAT) : null;
            const dateTimeTo = formValues.to ? DateTime.fromFormat(formValues.to, DATE_FORMAT) : null;

            const values = {
                name: formValues.name,
                link: formValues.link,
                from: dateTimeFrom && dateTimeFrom.isValid ? dateTimeFrom.toFormat(SERVER_DATE_FORMAT) : null,
                to: dateTimeTo && dateTimeTo.isValid ? dateTimeTo.toFormat(SERVER_DATE_FORMAT) : null,
                displayTexts: formValues.displayTexts,
            };

            if (isEditing && messageQuery.data) {
                updateMessageMutation.mutate(
                    { ...values, id: messageQuery.data.id, type: messageQuery.data.type },
                    { onSuccess: onSaveSuccess, onError: onSaveError },
                );
            } else {
                createMessageMutation.mutate(
                    { ...values, type: messageType },
                    { onSuccess: onSaveSuccess, onError: onSaveError },
                );
            }
        }
    };

    const handleDelete = async () => {
        if (
            (isEditing && !selectedLanguageDisplayText) ||
            (selectedLanguageDisplayText &&
                messageQuery.data?.displayTexts.some((it) => it.language === selectedLanguageDisplayText.language))
        ) {
            if (
                (await confirm({
                    msg: t('message.dialog.message', {
                        name: selectedLanguageDisplayText?.language ?? messageQuery.data?.name ?? '',
                    }),
                    title: t('message.dialog.title'),
                })) === 'yes'
            ) {
                confirmDelete();
            }
        }
        return;
    };

    const confirmDelete = () => {
        if (selectedLanguageDisplayText) {
            displayTextsFieldArray.remove(selectedLanguageDisplayText.index);
            handleBack();
        } else if (messageQuery.data) {
            deleteMessageMutation.mutate(messageQuery.data, {
                onError: onDeleteError,
                onSuccess: handleBack,
            });
        }
    };

    const getTitle = () => {
        let icon;
        if (!selectedLanguageDisplayText) {
            switch (messageType) {
                case MessageType.WELCOME:
                    icon = <TextBeforeIcon />;
                    break;
                case MessageType.GOODBYE:
                    icon = <TextAfterIcon />;
                    break;
                case MessageType.CONFIRMATION_MAIL:
                    icon = <EmailIcon />;
                    break;
                case MessageType.EMAIL:
                    icon = <EmailIcon />;
                    break;
            }
        }

        return {
            icon,
            value: t(`message.titles.${selectedLanguageDisplayText ? 'text' : messageType}`),
            onBack: handleCancel,
            tooltip: !selectedLanguageDisplayText ? t(`message.infoText.${messageType}`) : undefined,
        };
    };

    const handleCreateDisplayText = (language: MessageLanguage) => {
        const emptyMessageDisplayText = createEmptyMessageDisplayText(language);
        displayTextsFieldArray.append({ ...emptyMessageDisplayText });

        setSelectedLanguageDisplayText({
            ...emptyMessageDisplayText,
            index: displayTexts.length,
        });
    };

    useEffect(() => {
        if (!canUseConfirmationMail && messageId === MessageType.CONFIRMATION_MAIL) {
            navigate(PathUtils.generateUrl(Path.RESTAURANT_SETTINGS_MESSAGES, { restaurantId }));
        }
    }, [canUseConfirmationMail, messageId]);

    const isLoading =
        messageQuery.isInitialLoading ||
        createMessageMutation.isLoading ||
        updateMessageMutation.isLoading ||
        deleteMessageMutation.isLoading;

    const isSelectedLanguageDisplayTextValid =
        selectedLanguageDisplayText && errors ? !errors.displayTexts?.[selectedLanguageDisplayText.index] : true;

    const isSelectedLanguageDisplayTextDirty =
        selectedLanguageDisplayText &&
        displayTexts?.[selectedLanguageDisplayText.index] &&
        !isEqual(
            pick(selectedLanguageDisplayText, ['language', 'text', 'link']),
            pick(displayTexts[selectedLanguageDisplayText.index], ['language', 'text', 'link']),
        );

    const saveButtonDisabled = selectedLanguageDisplayText
        ? !(isSelectedLanguageDisplayTextValid && isSelectedLanguageDisplayTextDirty)
        : !(isValid && isDirty);

    useEffect(() => {
        if (messageQuery.isError) {
            if (messageQuery.error?.message) {
                snackbar({ msg: messageQuery.error.message, severity: 'error' });
            }
            handleBack();
        }
    }, [messageQuery.isError]);

    useEffect(() => {
        if (link && link.length > 4 && !link.startsWith('http')) {
            methods.setValue('link', `http://${link}`);
        }
    }, [link]);

    return (
        <Page
            name="message"
            title={getTitle()}
            actions={{
                save: {
                    disabled: saveButtonDisabled,
                    label: selectedLanguageDisplayText && t('common.buttons.ok'),
                    onClick: handleSave,
                },
                onCancel: handleCancel,
                onDelete: handleDelete,
            }}
            isLoading={isLoading}
        >
            <FormProvider {...methods}>
                <div id="message">
                    {selectedLanguageDisplayText ? (
                        <MessageDisplayTextFragment
                            type={messageType}
                            messageDisplayText={selectedLanguageDisplayText}
                        />
                    ) : (
                        <MessageFragment
                            type={messageType}
                            onCreateDisplayText={handleCreateDisplayText}
                            onEditDisplayText={setSelectedLanguageDisplayText}
                            isDefaultMessage={isDefaultMessage}
                            setIsDefaultMessage={setIsDefaultMessage}
                        />
                    )}
                </div>
            </FormProvider>
        </Page>
    );
};

export default Message;
